import ScrollReveal from 'scrollreveal';

const Reveal = {
    init() {
        Reveal.attachScrollReveal();
    },

    attachScrollReveal() {
        ScrollReveal().reveal('.reveal', {
            delay: 500,
            duration: 1000,
            mobile: false,
            viewOffset: {
                top: 200,
            },
        });

        ScrollReveal().reveal('.reveal-timeline', {
            delay: 0,
            duration: 1000,
            mobile: false,
            reset: false,
            viewFactor: 0.7,
        });
    },
};

Reveal.init();
